import { defineStore } from 'pinia'
import type { Ref } from 'vue'

/*
  We do not annotate with types from the SDK, since the whole job of the store is to call a method and save the result.
  Basically, getItem is any async function, and getItemRequest is just an async function that returns getItem
*/
type GetItem = (...args: any[]) => Promise<any>
type GetItemRequest = () => Promise<GetItem>

interface Options<GetItemReq extends GetItemRequest = GetItemRequest> {
  getItemRequest: GetItemReq
}

type Extending = Record<string, any> // we can extend a store in any way we want

const storeSetup = <GetItemReq extends GetItemRequest>({
  getItemRequest
}: Options<GetItemReq>) => {
  type ReceivedGetItem = Awaited<ReturnType<GetItemReq>>
  type T = Awaited<ReturnType<ReceivedGetItem>>

  const data = ref(null) as Ref<T | null>

  const fetchItem = async (
    ...args: Parameters<ReceivedGetItem>
  ): Promise<T> => {
    const result = await getItemRequest().then(request => request(...args))
    data.value = result

    return result
  }

  const updateData = (updates: Partial<T> = {}) => {
    if (data.value) {
      data.value = Object.assign({}, data.value, updates)
    }
  }

  return { data, fetchItem, updateData }
}

export const createItemStore = <
  GetItemReq extends GetItemRequest,
  E extends Extending = {}
>(
  key: string,
  options: Options<GetItemReq>,
  setup?: (context: ReturnType<typeof storeSetup<GetItemReq>>) => E
) =>
  defineStore(key, () => {
    const context = storeSetup(options)
    const extending = Object.assign({}, setup?.(context))

    return { ...context, ...extending }
  })
